import React from 'react';
import Button1 from '../Buttons/Button1-scroll';
import Typewriter from 'typewriter-effect';
// import background from '../../assets/bg.jpg';
import SketchWrapper from '../HeroBackground/SketchWrapper';
import ScrollDownButton from '../Buttons/ScrollDownButton';

const HeroSection = ({ title, text, buttonText }) => {
  return (
    <div
      className="relative top-12 z-10 mx-2 flex h-screen items-center justify-center text-white"
      // style={{ backgroundImage: `url(${background})` }}
    >
      {/* <SketchWrapper /> */}
      <div className="z-30 max-w-6xl text-center">
        <div className="mx-4 mb-4 flex flex-col text-5xl font-bold  md:text-7xl">
          {title}
          <div className="text-tertiary">
            <Typewriter
              className="type-header"
              options={{
                loop: true,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString('innovation.')
                  .pauseFor(3000)
                  .deleteAll()
                  .typeString('education.')
                  .pauseFor(3000)
                  .deleteAll()
                  .typeString('investments.')
                  .pauseFor(3000)
                  .deleteAll()
                  .start();
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <p className="mx-10 mb-6 max-w-2xl text-xl text-gray-300 md:mx-36 md:text-2xl">
            {text}
          </p>
        </div>
        <div className="transition delay-0 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
          <div className="relative flex flex-col items-center">
            <Button1 text={buttonText} link={'about'} />
          </div>
        </div>
        <ScrollDownButton />
      </div>
    </div>
  );
};

export default HeroSection;
