import {React, useState} from 'react';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

const PublicationsPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    
      const toggle = () => {
        setIsOpen(!isOpen);
      };

    return (
    <>
        <SideBar isOpen={isOpen} toggle={toggle} />
        <NavBar toggle={toggle} />
        <div className="min-h-screen pt-40 text-white">
            <h1>Coming Soon</h1>
            <p>Stay tuned for updates!</p>
        </div>
        <Footer />
    </>
    );
};

export default PublicationsPage;