import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { FaPencilAlt } from 'react-icons/fa';
import { FaChartLine } from 'react-icons/fa';
import standLogo from '../../assets/standwithcrypto-logo.svg';
import blockstartLogo from '../../assets/blockstart-logo.svg';
import encodeLogo from '../../assets/ENCODE_logo.png';
import RevealOnScroll from '../RevealOnScroll';

const InfoSection = () => {
  return (
    <>
      <div className="px-6 py-28 md:min-h-screen md:px-24">
        <RevealOnScroll>
          <p className="text-l font-bold text-primary">DEPARTMENTS</p>
          <h1 className="text-3xl font-bold text-white md:text-5xl">
            Learn. Invest. Build.
          </h1>

          <p className="text-l py-20 text-gray-300  md:px-80">
            Our society comprises students from all disciplines, backgrounds, and identities with the ambition of bringing together and educating people on blockchain, web3, and crypto.
          </p>
          <h2 className="text-2xl font-bold text-white">Our Partners</h2>
            <div className="flex flex-wrap justify-center gap-8 py-10">
            <a href="https://www.standwithcrypto.org" target="_blank" rel="noopener noreferrer">
              <img src={standLogo} className="h-16 w-auto" />
            </a>
            <a href="https://www.blockstart.eu" target="_blank" rel="noopener noreferrer">
              <img src={blockstartLogo} className="h-16 w-auto" />
            </a>
            <a href="https://www.encode.club" target="_blank" rel="noopener noreferrer">
              <img src={encodeLogo} className="h-16 w-auto" />
            </a>
            </div>
        </RevealOnScroll>
        <RevealOnScroll>
          <div className="flex flex-col justify-around text-left text-sm md:flex-row md:divide-x md:divide-gray-500">
            <div className="flex w-full flex-col space-y-8 pl-8 md:pt-6 md:w-1/3">
              <FaChartLine className="text-4xl text-primary" />
              <h2 className="text-xl font-bold text-white">Investing</h2>
              <p className="pr-6 text-gray-300">
                Each semester we publish investment reports covering crypto,
                examining their technology and utility while considering macroeconomic factors.
                Analysts also learn the basics of fundamental, technical, and market analysis.
                This sector is ideal for those interested in crypto and trading.
              </p>
            </div>
            <div className="flex w-full flex-col space-y-8 pl-8 pt-6 md:w-1/3">
            <FaPencilAlt className="text-4xl text-primary" />
              <h2 className="text-xl font-bold text-white">Writing</h2>
              <p className="pr-6 text-gray-300">
              The writing team reports on Web3 news and presents it in digestible terms
              to both newcomers and those with experience in blockchain.
              Writers publish a semesterly magazine on blockchain-related content including
              decentralised science (DeSci), web3 social commerce, and central bank digital
              currencies (CBDCs). This sector is well suited for gaining experience in financial journalism.
              </p>
            </div>
            <div className="flex w-full flex-col space-y-8 pl-8 md:w-1/3 pt-6">
              <FaWrench className="text-4xl text-primary" />
              <h2 className="text-xl font-bold text-white">Engineering</h2>
              <p className="pr-6 text-gray-300">
                Coming soon. We are currently recruiting talented computer scientists to develop our engineering sector. 
              </p>
            </div>
          </div>
        </RevealOnScroll>
      </div>
    </>
  );
};

export default InfoSection;
