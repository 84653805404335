import React, { useState } from 'react';
import Article from '../components/Articles/Article';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

const ArticlePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Article />
      <Footer />
    </>
  );
};

export default ArticlePage;
