import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import NavLink from './NavLink';
import Button1 from '../Buttons/Button1';
import logo from '../../assets/logo.png';
import Button2 from '../Buttons/Button2';

const NavBar = ({ toggle }) => {
  return (
    <nav className="fixed left-0 top-0 z-30 w-full bg-transparent text-white backdrop-blur">
      <div className="mx-4 flex flex-wrap items-center justify-between p-4 md:mx-12 md:justify-between">
        <Link
          className="flex items-center justify-center gap-4 font-bold"
          to="/"
        >
          <div className="h-20 w-20 rounded-full bg-white">
            <img src={logo} className="h-20 w-auto" alt="Logo" />
          </div>
          <div className="hidden text-left md:flex md:flex-col">
            <h1 className="text-2xl">Blockchain</h1>
            <p className="text-s text-primary">University of St. Andrews</p>
          </div>
        </Link>

        <div className="flex flex-row items-center justify-center gap-6">
          <div className="flex flex-row gap-6">
            <ul className="hidden md:flex">
              <NavLink text="About" link="/about" />
            </ul>
            <ul className="hidden md:flex">
              <NavLink text="Team" link="/team" />
            </ul>
            <ul className="hidden md:flex relative group">
              <NavLink text="Publications" link="/publications" />
              <div className="absolute hidden group-hover:block bg-black text-white mt-2 rounded shadow-lg">
                <ul className="flex flex-col">
                  <NavLink text="Magazine 24/25" link="/publications/magazine-24-25" />
                  <NavLink text="Investment Reports 24/25" link="/publications/investment-reports-24-25" />
                  <NavLink text="Magazine 23/24" link="https://stabsmagazine.my.canva.site/" />
                </ul>
              </div>
            </ul>
            <ul className="hidden md:flex">
              <NavLink text="Events" link="/events" />
            </ul>
          </div>
          <div className="flex space-x-4">
            <div className="hidden md:flex">
              <Button2 text={'Contact'} link="/contact" />
            </div>
            <div className="hidden md:flex">
              <Button1
                text="Join"
                link="https://www.yourunion.net/activities/societies/explore/9310/"
              />
            </div>
          </div>
          <div
            className="right-0 top-0 block text-3xl md:hidden"
            onClick={toggle}
          >
            <FaBars />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
