import { React, useState } from 'react';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <div className="min-h-screen pt-40 text-white">
        <a
          href="mailto:blockchain@st-andrews.ac.uk"
          className="text-xl no-underline hover:opacity-75"
        >
          Contact us: <span className="underline">blockchain@st-andrews.ac.uk</span>
        </a>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
