import { useState, useEffect } from 'react';
import NavBar from '../components/NavBar/NavBar';
import SideBar from '../components/SideBar/SideBar';
import HeroSection from '../components/HeroSection/HeroSection';
import Footer from '../components/Footer/Footer';
import InfoSection from '../components/InfoSection/InfoSection';
import PageSection from '../components/PageSection/PageSection';
import blockImg from '../assets/blockchain.png';
import engImg from '../assets/engineering.png';
import invImg from '../assets/investing.png';

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <div
        className={`transform transition-all delay-500 duration-1000 ease-in-out ${
          isLoaded ? 'translate-y-0 opacity-100' : '-translate-y-5 opacity-0'
        }`}
      >
        <HeroSection
          title="St. Andrews's hub for blockchain"
          text="St Andrews’ first student-run blockchain and web3 organisation."
          buttonText="Get Started"
        />
      </div>
      <hr id="about" />
        <InfoSection />
      <hr />
      {/*<hr id="about" />
      <PageSection
        // id="about"
        title="About"
        text={
          <>
            <p>
              In April of 2022, seven students founded the St Andrews Blockchain
              Society: a student run group focused on blockchain education
              through writing, investing, and programming.
            </p>
            <br />
            <p>
              Our society is composed of students from all disciplinary
              backgrounds: computer science, economics, divinity, geography,
              film, etc.
            </p>
            <br />
            <p>
              Through our efforts we hope members of our community recognize the
              potential web3 has to offer for virtual economies.
            </p>
          </>
        }
        img={blockImg}
      />
      <hr />
      <PageSection
        id="education"
        title="Investing"
        reverse={true}
        text={
          <>
            <p>
              Join a small group of students as we unravel the basics of
              fundamental, technical, and sentiment analysis.
            </p>
            <br />
            <p>
              Through a traditional-finance oriented curriculum you can learn
              about the foundation of market research before creating your own
              proposal on a web3 project or company.
            </p>
          </>
        }
        img={invImg}
      />
      <hr />
      <PageSection
        id="engineering"
        title={'Engineering'}
        text={
          <>
            <p>
              The engineering group is designed to undertake semester-long
              projects as a team, deciphering the programming languages commonly
              used in web development and blockchain protocols, such as
              Javascript, HTML, Rust, and Solidity.
            </p>
          </>
        }
        img={engImg}
      /> */}
      <Footer />
    </>
  );
}

export default Home;
